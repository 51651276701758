import { Box, Button, Card, CardBody, CardFooter, CardHeader, Flex, Grid, Heading, HStack, Image, Input, Link, Progress, Stack, Text, useNumberInput } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import img from '../Images/NFT.png';

import { useAccount } from 'wagmi';

import { ethers } from 'ethers';
import AlphaLions from '../artifacts/contracts/LionNFT.sol/LionNFT.json';
import AlphaToken from '../artifacts/contracts/LionToken.sol/LionToken.json';
import Swal from 'sweetalert2';


const Toast = Swal.mixin({
  toast: true,
  position: 'center',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,

  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})


const swalOptions = {
  icon: 'info',
  title: 'Transaction Pending',
  text: 'Please wait while the transaction is being processed...',
  allowOutsideClick: true,
  showCancelButton: false,
  showConfirmButton: true,
  timer: 5000, // Set timer for 5 seconds (5000 milliseconds)
  onBeforeOpen: () => {
    Swal.showLoading();
  }
};

const ALaddress = '0x4743305b5D4814239971D55FF8e4f3dB64E5557A';
const tokenAddress = '0xca9d8ef4ba15ae66347b3d22afe2970b89980f88';
const EthCost = "75000000000000000"
const TokenCost = "14250"



const Mint = () => {




  const handleButtonClick = () => {
    if (toggleValue === 'ETH') {
      handleEthButtonClick();
      console.log("ETH MINT")
    } else if (toggleValue === 'TOKENS') {
      handleTokensButtonClick();
      console.log("TKN MINT")
    }
  };





//------------SET MINT FUNCTIONS HERE----------------------
  const handleEthButtonClick = () => {
    console.log('ETH button clicked');
    Ethmint()
    
  };
//------------SET MINT FUNCTIONS HERE----------------------
  const handleTokensButtonClick = () => {
    // Handle button click for TOKENS
    console.log('TOKENS button clicked');
    Tokenmint()
    
  };

  const handleToggle = (value) => {
    setToggleValue(value);
  };











    const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
        step: 1,
        defaultValue: 1,
        min: 1,
        max: 3,
        precision: 0,
    })
  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps()
  const value = input.value;
   console.log(value);

  const { address, isConnecting, isDisconnected} = useAccount();
  const [toggleValue, setToggleValue] = useState('ETH');

  const [data, setData] = useState({});
  const [owner, setOwner] = useState(false);

  useEffect(() => {
    getContractOwner();
    fetchData();
  }, [address])

  async function getContractOwner() {
    if(typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ALaddress, AlphaLions.abi, provider);
      try {
       if(address) {
          const owner = await contract.owner();
          if(owner.toLowerCase() === address.toLowerCase()) {
            setOwner(true);
          } else {
            setOwner(false);
          }
       }
      }
      catch (err) {
        console.log(err.message);
      }
    }
  }

  async function fetchData() {
    if(typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ALaddress, AlphaLions.abi, provider);
      try {
        const mintPrice = await contract.ethCost();
        const totalSupply = await contract.totalSupply();
        

        const object = {"price": String(mintPrice), "totalSupply": String(totalSupply)};
        setData(object);
      }
      catch (err) {
        console.log(err.message);
      }
    }
  }


  async function approveFunds() {
    if (typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
  
      // Replace with your BUSD token contract address and ABI
      const contract = new ethers.Contract(tokenAddress, AlphaToken.abi, signer);
  
      try {
        const amountToApprove = ethers.utils.parseUnits((TokenCost * value).toString(), 18); // Assuming 18 decimal places
  
        const transaction = await contract.approve(ALaddress, amountToApprove.toHexString());
      
  
        await transaction.wait( Swal.fire(swalOptions));
        Toast.fire({icon: 'success',title: 'Approve Success'})
        Swal.fire({
          icon: 'success',
          title: 'Transaction Successful',
          text: 'Approval Successful!',
        })
       
      }
      catch (error) {
       
        console.log(error.message);
        Swal.fire({
          icon: 'error',
          title: 'Transaction Failed',
          text: error.reason,
        });
        
      }
    }
  }
  
  

  async function Tokenmint() {
    if(typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(ALaddress, AlphaLions.abi, signer);

      try {
    
        const transaction = await contract.Tokenmint(value);
     
      
        await transaction.wait( Swal.fire(swalOptions));
        Toast.fire({icon: 'success',title: 'Mint Success'})
        Swal.fire({
          icon: 'success',
          title: 'Transaction Successful',
          text: 'Minted Successfully!',
        })
       
      }
      catch (error) {
       
        console.log(error.message);
        Swal.fire({
          icon: 'error',
          title: 'Transaction Failed',
          text: error.reason,
        });
        
      }
    }
  }

  async function Ethmint() {
    if (typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(ALaddress, AlphaLions.abi, signer);
        try {
            const newCost = EthCost * value; // Ensure EthCost and value are defined
            const refined = newCost.toString();
            console.log(newCost);
            const transaction = await contract.ethmint(value, { value: refined }); // Correct the variable name 'newCos' to 'newCost'
            await transaction.wait( Swal.fire(swalOptions));
                                Toast.fire({icon: 'success',title: 'Mint Success'})
                                Swal.fire({
                                  icon: 'success',
                                  title: 'Transaction Successful',
                                  text: 'Mint Successful!',
                                })
                               
                              }
                              catch (error) {
                               
                                console.log(error.message);
                                Swal.fire({
                                  icon: 'error',
                                  title: 'Transaction Failed',
                                  text: error.reason,
                                });
                                
                              }
                            }
                          }


  async function withdraw() {
    if(typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(ALaddress, AlphaLions.abi, signer);
      try {
        const transaction = await contract.withdrawETH();
        await transaction.wait();
      }
      catch (err) {
        console.log(err.message);
      }
    }
  }


    return (
        <Box  mb="100px">

            <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}} gap="60px">
                <Stack order={{base: '2', md:'1'}} spacing="25px" >
                    <Text color="white" fontSize="5xl" fontWeight="bold">
                        Disappearing Devs
                    </Text>
                    <br></br>
          
      
           
                    <Flex justify="space-between" align="center">
                        <Text className='nftMint' fontSize="18px" color="primary.600">
                            Supply: <span style={{fontWeight: 'bold', color: 'white'}}>1000</span>
                        </Text>
                        <br></br>

                        <Text className='nftMint' fontSize="18px" color="primary.600">
                            ETH PRICE: <span style={{fontWeight: 'bold', color: 'white'}}>0.075 ETH</span>
                        </Text>
                        <br></br>
                        <Text className='nftMint' fontSize="18px" color="primary.600">
                            INNOVA PRICE: <span style={{fontWeight: 'bold', color: 'white'}}>14050 INNOVA</span>
                        </Text>

                    </Flex>

                    <Flex justify="space-between" align="center" wrap='wrap' gap="15px">
                    <Text className='nftMint'>
                        Network: <span>Ethereum</span>
                    </Text>


                    </Flex>

                    <Text className='nftMint1'>
                    Introducing our "Disappearing Dev Collection" – it's not just your ordinary NFT image. It's a magical gateway to a world of fun and passive income! 🚀🎉<br></br>

Through the power of the ERC-721 standard, we've transformed NFTs into a source of continuous rewards. But that's not all! We've added a dash of gamification to spice things up. 🎮<br></br>

With our small gameification database, you can now playfully give and receive points to and from your friends. Earn those points, and you'll unlock even more exciting rewards. It's not just collecting NFTs; it's a journey of endless possibilities and delight! 🌟🎁<br></br>

Get ready to level up your NFT game with the "Disappearing Dev Collection." Join us on this adventure, where fun meets profit! 💰🎈 </Text>
                    

                    <Link href='https://opensea.io/collection/disappearing-devs' _hover={{textDecoration: 'none'}} target="_blank">
                        <Button border="1px"  w="100%">
                            View on Opensea
                        </Button>
                    </Link>

                    { owner && (
                        <>
                            <Button border="1px" onClick={withdraw}>withdraw</Button>
                        </>
                    )
                    }

                </Stack>

                <Stack order={{base: '1', md:'1'}} spacing="25px">
                    <Flex justify="center">
                      <Image src={img} alt="nftImage"borderRadius="30px" w={{base: 'auto', lg: "300px"}} h="300px" objectFit="contain"/>
                    </Flex>

                    <Box>
                        <Flex justify="space-between" fontSize="17px">
                            <span className='nftMint'>Total minted</span>
                            <Text className='nftMint'><span style={{color: 'white', fontWeight: 'bold'}}>{((data.totalSupply/1000)*100).toFixed(1)}%</span> ({data.totalSupply}/1000)</Text>
                        </Flex>
                        <Box mt="5px">
                            <Progress bg="secondary.800" size="md" value={((data.totalSupply/1000)*100).toFixed(0)} colorScheme="green" borderRadius="full"/>
                        </Box>
                    </Box>

                    <Card bg="secondary.700" color="white">
                        <CardHeader fontSize="18px" display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" gap="15px">
                        <Text className='nftMint' >
                            Price: <span style={{fontWeight: 'bold'}}>0.075 ETH</span>
                        </Text>
                        <HStack maxW='320px'>
                            <Button {...dec} colorScheme="cyan">-</Button>
                            <Input {...input}/>
                            <Button {...inc} colorScheme="cyan">+</Button>
                        </HStack>
                        </CardHeader>

                        <CardBody>
                        <div className="toggle-container">
  <button className={`toggle-button ${toggleValue === 'ETH' ? 'active' : ''}`} onClick={() => handleToggle('ETH')}>
    ETH
  </button>
  <button className={`toggle-button ${toggleValue === 'TOKENS' ? 'active' : ''}`} onClick={() => handleToggle('TOKENS')}>
    TOKENS
  </button>
</div>
<br></br>

{toggleValue === 'TOKENS' && (
  <Button className='buttontxt' border="1px" w="100%" colorScheme="white" onClick={approveFunds} disabled={isDisconnected && true}>
    Approve Tokens
  </Button>


  
  
)}
<div>
  <br></br>
</div>



<Button className='buttontxt' border="1px" w="100%" colorScheme="white" onClick={handleButtonClick} disabled={isDisconnected && true}>
  Mint {value}
</Button>
</CardBody>

                    </Card>
                </Stack>
            </Grid>
            <br></br>
          
    
         

        </Box>
    );
};

export default Mint;