import { Box, Flex, Grid, Image, Card, CardBody, CardFooter,Stack, Text, Button, CardHeader, Center } from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import 'reactjs-popup/dist/index.css';
import { ethers } from 'ethers';
import { useAccount } from 'wagmi';
import LionTeam from '../artifacts/contracts/LionTeam.sol/LionTeam.json';
import Swal from 'sweetalert2';
import '../Styles/Home.css';
import Logo from '../Images/logo.png'

   





 



const Toast = Swal.mixin({
  toast: true,
  position: 'center',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,

  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const swalOptions = {
  icon: 'info',
  title: 'Transaction Pending',
  text: 'Please wait while the transaction is being processed...',
  allowOutsideClick: true,
  showCancelButton: false,
  showConfirmButton: true,
  timer: 5000, // Set timer for 5 seconds (5000 milliseconds)
  onBeforeOpen: () => {
    Swal.showLoading();
  }
};

const TeamContractAddress = '0xc6701D6B97509740c9C610EAce02837EE81Ce01a'; 


const NFTHead = () => {




 

 
  const [ethamount, setethamount] = useState();
  const [ethreciever, setethreciever] = useState();
  const [ethreason, setethreason] = useState();
  const [ethipfs, setethipfs] = useState();
  const [ethipfsTotal, setethipfsTotal] = useState();
  const [ethtg, setethtg] = useState();

  const [tokenamount, settokenamount] = useState();
  const [tokenreciever, settokenreciever] = useState();
  const [tokenreason, settokenreason] = useState();
  const [tokenipfs, settokenipfs] = useState();
  const [tokentg, settokentg] = useState();
  const [tokenipfsTotal, settokenipfsTotal] = useState();




  const [marketing, setclaimablemarketing] = useState();
  const [tokenBalance, setBalance] = useState();
  const [currentDeposit, setCurrentDeposit] = useState();
  const [TokencurrentDeposit, setTokenCurrentDeposit] = useState();

  
  const { address, isDisconnected } = useAccount();


  const [depositValue, setDepositValueAmount] = useState();
  const [DepositValueTokens, setDepositValueAmountTokens] = useState();
  
 

 
 

   
       
  useEffect(() => {
    viewMarketingBalance()
    viewCurrentDeposit()
    viewTokenBalance()
    viewTokenCurrentDeposit()
 
  
   
    
}, [address])


//------------SET MINT FUNCTIONS HERE--------------------


    const updateData = () => {
      if(isDisconnected) {
        viewMarketingBalance()
        viewCurrentDeposit()
        viewTokenBalance()
        viewTokenCurrentDeposit()
   
        updateData();
      }
    }

    const handleDepositChange = (e) => {
        setDepositValueAmount(e.target.value);
    };

    const handleDepositChangeTokens = (e) => {
        setDepositValueAmountTokens(e.target.value);
    };

   


























    

    async function viewMarketingBalance() {
      if(typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(TeamContractAddress, LionTeam.abi, provider);
        try {
          if(address) {
            const rum = await contract.amountForMarketing();
            let total = (rum / 10**18).toFixed(2);
            let final = total.toString() + " ETH"
            
            setclaimablemarketing(final) 
          }}
          catch (err) {
            console.log(err.message);
          }
        }}
        

        async function viewCurrentDeposit() {
            if(typeof window.ethereum !== 'undefined') {
              const provider = new ethers.providers.Web3Provider(window.ethereum);
              const contract = new ethers.Contract(TeamContractAddress, LionTeam.abi, provider);
              try {
                if(address) {
                  const rum = await contract.marketingWithdrawlNumber();
                  let total = rum.toString();
                  
                  
                  setCurrentDeposit(total) 
                }}
                catch (err) {
                  console.log(err.message);
                }
              }}




              async function viewTokenCurrentDeposit() {
                if(typeof window.ethereum !== 'undefined') {
                  const provider = new ethers.providers.Web3Provider(window.ethereum);
                  const contract = new ethers.Contract(TeamContractAddress, LionTeam.abi, provider);
                  try {
                    if(address) {
                      const rum = await contract.tokenWithdrawlNumber();
                      let total = rum.toString();
                      
                      
                      setTokenCurrentDeposit(total) 
                    }}
                    catch (err) {
                      console.log(err.message);
                    }
                  }}



              async function viewTokenBalance() {
                if(typeof window.ethereum !== 'undefined') {
                  const provider = new ethers.providers.Web3Provider(window.ethereum);
                  const contract = new ethers.Contract(TeamContractAddress, LionTeam.abi, provider);
                  try {
                    if(address) {
                      const rum = await contract.tokenHoldings();
                      let total = (rum / 10**18).toFixed(2);
                      let final = total.toString() + " INNOVA"
                      
                      setBalance(final) 
                    }}
                    catch (err) {
                      console.log(err.message);
                    }
                  }}




                  async function viewEthWithdrawls(depositValue) {
                    if(typeof window.ethereum !== 'undefined') {
                      const provider = new ethers.providers.Web3Provider(window.ethereum);
                      const contract = new ethers.Contract(TeamContractAddress, LionTeam.abi, provider);
                      try {
                        if(address) {
                          const rum = await contract.withdrawl(depositValue);
                          let amount ="ETH Spent: " + (rum[1] / 10**18).toFixed(2);
                          let reciever = "Receiver: " + rum[2].toString();
                          let reason = "Reason: " + rum[3].toString();
                          let ipfs = rum[4].toString();
                          let ipfsTotal = "IPFS Link: " + rum[4].toString();
                          let tg = "Telegram: " +rum[5].toString();

                          setethamount(amount)
                          setethreciever(reciever)
                          setethreason(reason)
                          setethipfsTotal(ipfsTotal)
                          setethipfs(ipfs)
                          setethtg(tg)
                        }}
                        catch (err) {
                          console.log(err.message);
                        }
                      }}



                      async function viewTokenWithdrawls(DepositValueTokens) {
                        if(typeof window.ethereum !== 'undefined') {
                          const provider = new ethers.providers.Web3Provider(window.ethereum);
                          const contract = new ethers.Contract(TeamContractAddress, LionTeam.abi, provider);
                          try {
                            if(address) {
                              const rum = await contract.Tokenwithdrawl(DepositValueTokens);
                              let amount ="INNOVA Spent: " + (rum[1] / 10**18).toFixed(2);
                              let reciever = "Receiver: " + rum[2].toString();
                              let reason = "Reason: " + rum[3].toString();
                              let ipfs = rum[4].toString();
                              let ipfsTotal = "IPFS Link: " + rum[4].toString();
                              let tg = "Telegram: " +rum[5].toString();
    
                              settokenamount(amount)
                              settokenreciever(reciever)
                              settokenreason(reason)
                              settokenipfsTotal(ipfsTotal)
                              settokenipfs(ipfs)
                              settokentg(tg)
                            }}
                            catch (err) {
                              console.log(err.message);
                            }
                          }}

                 

                    

                     








return (

  <Box mb="100px">
            <Box>

                <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)'}} gap="1px">

                    <Card className='NFTHead'    textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='headertxt'>
                          INNOVASIGHT BETA V1.0
                        
                        </CardHeader>
                        <CardBody>
                        <Text className='cardtxt1'    color="whiteAlpha.800" >  Innovasight is more than just a smart contract; it's the bedrock of transparency within our project. It serves as the guardian of our token's tax funds, ensuring that every transaction is meticulously accounted for.<br></br><br></br> When we initiate a withdrawal, Innovasight demands the vital details: the recipient's address, the precise amount, an IPFS link for proof, the reason behind the transaction, and even the recipient's Telegram contact.<br></br><br></br> With Innovasight, trust and transparency are more than just buzzwords; they are the cornerstones of our decentralized ecosystem, ensuring that every action is conducted openly and accountably</Text>
               
         

                     
                     </CardBody>
                     
                    
                    <br></br>

                    <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)'}} gap="10px">
                    

                    <Card className='NFTHead'   textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                    <Text color="whiteAlpha.800" >Current Marketing Funds </Text>
                   <Text className="responseTop" color="whiteAlpha.800">{marketing}</Text>
                   </Card>
                   <Card className='NFTHead'   textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                    <Text color="whiteAlpha.800" >Current ETH Withdrawl Number</Text>
                   <Text className="responseTop" color="whiteAlpha.800">{currentDeposit}</Text>
                   </Card>
                   <Card className='NFTHead'   textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                    <Text color="whiteAlpha.800" >Current Tokens Withdrawl Number</Text>
                   <Text className="responseTop" color="whiteAlpha.800">{TokencurrentDeposit}</Text>
                   </Card>
                   <Card className='NFTHead'   textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                    <Text color="whiteAlpha.800" >INNOVA Token Balance</Text>
                   <Text className="responseTop" color="whiteAlpha.800">{tokenBalance}</Text>
                   </Card>
                   </Grid>  
                   <br></br>





                    <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}} gap="10px">
                    
                   
                    <Card className='NFTHead'   textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                    <div className='padd'></div>
                    <Text fontSize='20px' color="whiteAlpha.800" >View INNOVASIGHT ETH Withdrawls</Text>
                    <div className='padd'></div>
                        <input type="text" className="form-control" onChange={handleDepositChange} value={depositValue} placeholder="Withdrawl Number"></input> 
                        <br>
                        </br>
                        <Text color="whiteAlpha.800" >{ethamount}</Text>
                        <div className='padd'></div>
                        <Text color="whiteAlpha.800" >{ethreciever}</Text>
                        <div className='padd'></div>
                        <Text color="whiteAlpha.800" >{ethreason}</Text>
                        <div className='padd'></div>
                        <a href={ethipfs} target="_blank" rel="noopener noreferrer">
                        <Text color="whiteAlpha.800">{ethipfsTotal}</Text>
                        </a>
                        <div className='padd'></div>
                        <Text color="whiteAlpha.800" >{ethtg}</Text>
                        
                

                      
                        <Button className='btnsubmit1' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => viewEthWithdrawls(depositValue)}>
                    <font><b>View INNOVASIGHT ETH Withdrawl</b></font>
                    </Button>
                    <br></br>
                    </Card>

                    <Card className='NFTHead' padding='30px'  textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                    <Text className='cardtxt1'color="whiteAlpha.800"> To view ETH withdrawals within our Innovasight smart contract, users can simply enter a withdrawal number below the maximum value displayed above and click 'View ETH Withdrawal.'<br></br><br></br> This feature allows our community members to access information about past ETH withdrawals made from the smart contract, including details such as the recipient's address, withdrawal amount, associated IPFS link, reason for the withdrawal, and the recipient's Telegram contact. <br></br><br></br>By providing this functionality, Innovasight ensures full transparency, allowing users to review and verify the transactions made from the contract without executing any new transactions themselves.</Text>
                 
                  </Card>
                  </Grid>

                   

         


                  <br></br>
<br></br>


                  <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}} gap="10px">
               

                  <Card className='NFTHead' padding='30px'  textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                    <Text className='cardtxt1'color="whiteAlpha.800"> To view INNOVA withdrawals within our Innovasight smart contract, users can simply enter a withdrawal number below the maximum value displayed above and click 'View INNOVA Withdrawal.'<br></br><br></br> This feature allows our community members to access information about past INNOVA withdrawals made from the smart contract, including details such as the recipient's address, withdrawal amount, associated IPFS link, reason for the withdrawal, and the recipient's Telegram contact. <br></br><br></br>By providing this functionality, Innovasight ensures full transparency, allowing users to review and verify the transactions made from the contract without executing any new transactions themselves.</Text>
                 
                  </Card>


                    <Card className='NFTHead'   textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                    <div className='padd'></div>
                    <Text fontSize='20px' color="whiteAlpha.800" >View INNOVASIGHT Token Withdrawls</Text>
                    <div className='padd'></div>
                        <input type="text" className="form-control" onChange={handleDepositChangeTokens} value={DepositValueTokens} placeholder="Withdrawl Number"></input> 
                        <br>
                        </br>
                       
                        <Text color="whiteAlpha.800" >{tokenamount}</Text>
            <div className='padd'></div>
                        <Text color="whiteAlpha.800" >{tokenreciever}</Text>
                        <div className='padd'></div>
                        <Text color="whiteAlpha.800" >{tokenreason}</Text>
                        <div className='padd'></div>
                        <a href={tokenipfs} target="_blank" rel="noopener noreferrer">
                        <Text color="whiteAlpha.800">{tokenipfsTotal}</Text>
                        </a>
                        <div className='padd'></div>
                        <Text color="whiteAlpha.800" >{tokentg}</Text>
                  
                        <Button className='btnsubmit1' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => viewTokenWithdrawls(DepositValueTokens)}>
                    <font><b>View INNOVA Withdrawl</b></font>
                    </Button>
                
                    </Card>
                    </Grid>
                           
                   




<br></br>




<Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)'}} gap="1px">



</Grid>
                      
                    
               




                    <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}} gap="1px">
 
                   

                    </Grid>  
                    </Card>
                    </Grid>  
                    

                    
                 
                 
             </Box>

        </Box>
    );
};
export default NFTHead;