import { Box, Flex, Grid, Image, Card, CardBody, CardFooter,Stack, Text, Button, CardHeader, Center } from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import 'reactjs-popup/dist/index.css';
import { ethers } from 'ethers';
import { useAccount } from 'wagmi';
import LionTeam from '../artifacts/contracts/LionTeam.sol/LionTeam.json';
import Swal from 'sweetalert2';
import '../Styles/Home.css';
import Logo from '../Images/logo.png'

   





 



const Toast = Swal.mixin({
  toast: true,
  position: 'center',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,

  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const swalOptions = {
  icon: 'info',
  title: 'Transaction Pending',
  text: 'Please wait while the transaction is being processed...',
  allowOutsideClick: true,
  showCancelButton: false,
  showConfirmButton: true,
  timer: 5000, // Set timer for 5 seconds (5000 milliseconds)
  onBeforeOpen: () => {
    Swal.showLoading();
  }
};

const TeamContractAddress = '0xc6701D6B97509740c9C610EAce02837EE81Ce01a'; 


const NFTHead = () => {


 



  const [fileUrl, setFileUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [marketing, setclaimablemarketing] = useState();
  const [NFT, setclaimableNFT] = useState();
  const [tokenBalance, setBalance] = useState();
  const [admin1Balance, setAdmin1Balance] = useState();
  const [admin2Balance, setAdmin2Balance] = useState();
  const [equityBalance, setequityBalance] = useState();
  
  const { address, isDisconnected } = useAccount();

  const [depositaddressReasonToken, setDepositValueReasonTokens] = useState();
  const [depositaddressReasonEth, setDepositValueReasonEth] = useState();
  const [depositamounttokens, setDepositValueTokens] = useState();
  const [depositamounteth, setDepositValueAmount] = useState();
  const [depositrecievertokens, setDepositRecieverTokens] = useState();
  const [depositrecievereth, setDepositRecieverEth] = useState();
  const [depositipfstokens, setDepositIPFSTokens] = useState();
  const [depositipfseth, setDepositIPFSEth] = useState();
  const [deposittelegramtokens, setDepositTelegramToken] = useState();
  const [deposittelegrameth, setDepositTelegramEth] = useState();

 

 
 

   
       
  useEffect(() => {
    viewMarketingBalance()
    viewNFTBalance()
    viewTokenBalance()
    viewAdmin1Balance()
    viewAdmin2Balance()
    viewEquityBalance()
    updateData();
  
   
    
}, [address])


//------------SET MINT FUNCTIONS HERE--------------------


    const updateData = () => {
      if(isDisconnected) {
        viewMarketingBalance()
        viewNFTBalance()
        viewTokenBalance()
        viewAdmin1Balance()
        viewAdmin2Balance()
        viewEquityBalance()

      }
      viewMarketingBalance()
        viewNFTBalance()
        viewTokenBalance()
        viewAdmin1Balance()
        viewAdmin2Balance()
        viewEquityBalance()
    }

    const handleDepositAmountChangeEth = (e) => {
        setDepositValueAmount(e.target.value);
    };

    const handleDepositAmountChangeTokens = (e) => {
        setDepositValueTokens(e.target.value);
      };

      const handleDepositAddressChangeEth = (e) => {
        setDepositRecieverEth(e.target.value);
      };

      const handleDepositAddressChangeTokens = (e) => {
        setDepositRecieverTokens(e.target.value);
      };

      const handleDepositChangeReasonEth = (e) => {
        setDepositValueReasonEth(e.target.value);
      };

      const handleDepositChangeReasonTokens = (e) => {
        setDepositValueReasonTokens(e.target.value);
      };

      const handleDepositChangeIPFSTokens = (e) => {
        setDepositIPFSTokens(e.target.value);
      };

      const handleDepositChangeIPFSEth = (e) => {
        setDepositIPFSEth(e.target.value);
      };

      const handleDepositChangeTelegramEth = (e) => {
        setDepositTelegramEth(e.target.value);
      };

      const handleDepositChangeTelegramTokens = (e) => {
        setDepositTelegramToken(e.target.value);
      };



























    

    async function viewMarketingBalance() {
      if(typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(TeamContractAddress, LionTeam.abi, provider);
        try {
          if(address) {
            const rum = await contract.amountForMarketing();
            let total = (rum / 10**18).toFixed(2);
            let final = total.toString() + " ETH"
            
            setclaimablemarketing(final) 
          }}
          catch (err) {
            console.log(err.message);
          }
        }}


        async function viewNFTBalance() {
            if(typeof window.ethereum !== 'undefined') {
              const provider = new ethers.providers.Web3Provider(window.ethereum);
              const contract = new ethers.Contract(TeamContractAddress, LionTeam.abi, provider);
              try {
                if(address) {
                  const rum = await contract.amountForNFT();
                  let total = (rum / 10**18).toFixed(2);
                  let final = total.toString() + " ETH"
                  
                  setclaimableNFT(final) 
                }}
                catch (err) {
                  console.log(err.message);
                }
              }}

              async function viewTokenBalance() {
                if(typeof window.ethereum !== 'undefined') {
                  const provider = new ethers.providers.Web3Provider(window.ethereum);
                  const contract = new ethers.Contract(TeamContractAddress, LionTeam.abi, provider);
                  try {
                    if(address) {
                      const rum = await contract.tokenHoldings();
                      let total = (rum / 10**18).toFixed(2);
                      let final = total.toString() + " INNOVA"
                      
                      setBalance(final) 
                    }}
                    catch (err) {
                      console.log(err.message);
                    }
                  }}

                  async function viewAdmin1Balance() {
                    if(typeof window.ethereum !== 'undefined') {
                      const provider = new ethers.providers.Web3Provider(window.ethereum);
                      const contract = new ethers.Contract(TeamContractAddress, LionTeam.abi, provider);
                      try {
                        if(address) {
                          const rum = await contract.amountForAdmin1();
                          let total = (rum / 10**18).toFixed(2);
                          let final = total.toString() + " ETH"
                          
                          setAdmin1Balance(final) 
                        }}
                        catch (err) {
                          console.log(err.message);
                        }
                      }}

                      async function viewAdmin2Balance() {
                        if(typeof window.ethereum !== 'undefined') {
                          const provider = new ethers.providers.Web3Provider(window.ethereum);
                          const contract = new ethers.Contract(TeamContractAddress, LionTeam.abi, provider);
                          try {
                            if(address) {
                              const rum = await contract.amountForAdmin2();
                              let total = (rum / 10**18).toFixed(2);
                              let final = total.toString() + " ETH"
                              
                              setAdmin2Balance(final) 
                            }}
                            catch (err) {
                              console.log(err.message);
                            }
                          }}

                      async function viewEquityBalance() {
                        if(typeof window.ethereum !== 'undefined') {
                          const provider = new ethers.providers.Web3Provider(window.ethereum);
                          const contract = new ethers.Contract(TeamContractAddress, LionTeam.abi, provider);
                          try {
                            if(address) {
                              const rum = await contract.amountForexpenses();
                              let total = (rum / 10**18).toFixed(2);
                              let final = total.toString() + " ETH"
                              
                              setequityBalance(final) 
                            }}
                            catch (err) {
                              console.log(err.message);
                            }
                          }}
















                          async function handleClaimAdmin1() {
                            if(typeof window.ethereum !== 'undefined') {
                              const provider = new ethers.providers.Web3Provider(window.ethereum);
                              const signer = provider.getSigner();
                              const contract = new ethers.Contract(TeamContractAddress, LionTeam.abi, signer);
                        
                              try {
                                
                                const transaction = await contract.withdrawlAdmin1();
                                await transaction.wait( Swal.fire(swalOptions));
                                Toast.fire({icon: 'success',title: 'Mint Success'})
                                Swal.fire({
                                  icon: 'success',
                                  title: 'Transaction Successful',
                                  text: 'Sent Points Successfully!',
                                })
                               
                              }
                              catch (error) {
                               
                                console.log(error.message);
                                Swal.fire({
                                  icon: 'error',
                                  title: 'Transaction Failed',
                                  text: error.reason,
                                });
                                
                              }
                            }
                          }
                      
                      
                          async function handleClaimAdmin2() {
                              if(typeof window.ethereum !== 'undefined') {
                                const provider = new ethers.providers.Web3Provider(window.ethereum);
                                const signer = provider.getSigner();
                                const contract = new ethers.Contract(TeamContractAddress, LionTeam.abi, signer);
                          
                                try {
                                  
                                  const transaction = await contract.withdrawlAdmin2();
                                  await transaction.wait( Swal.fire(swalOptions));
                                  Toast.fire({icon: 'success',title: 'Claim Success'})
                                  Swal.fire({
                                    icon: 'success',
                                    title: 'Transaction Successful',
                                    text: 'Sent Points Successfully!',
                                  })
                                 
                                }
                                catch (error) {
                                 
                                  console.log(error.message);
                                  Swal.fire({
                                    icon: 'error',
                                    title: 'Transaction Failed',
                                    text: error.reason,
                                  });
                                  
                                }
                              }
                            }

                            async function handleSendTokens() {
                              if(typeof window.ethereum !== 'undefined') {
                                const provider = new ethers.providers.Web3Provider(window.ethereum);
                                const signer = provider.getSigner();
                                const contract = new ethers.Contract(TeamContractAddress, LionTeam.abi, signer);
                                const depositValuetInWei = ethers.utils.parseEther(depositamounttokens);
                                console.log(depositValuetInWei + "Amount")
                          
                                try {

                                  
                                  const transaction = await contract.withdrawlTokens(depositValuetInWei,depositrecievertokens,depositaddressReasonToken,depositipfstokens,deposittelegramtokens);
                                  await transaction.wait( Swal.fire(swalOptions));
                                  Toast.fire({icon: 'success',title: 'Tokens Sent Success'})
                                  Swal.fire({
                                    icon: 'success',
                                    title: 'Transaction Successful',
                                    text: 'Sent Successfully!',
                                  })
                                 
                                }
                                catch (error) {
                                 
                                  console.log(error.message);
                                  Swal.fire({
                                    icon: 'error',
                                    title: 'Transaction Failed',
                                    text: error.reason,
                                  });
                                  
                                }
                              }
                            }




                            async function handleSendEth() {
                              if(typeof window.ethereum !== 'undefined') {
                                const provider = new ethers.providers.Web3Provider(window.ethereum);
                                const signer = provider.getSigner();
                                const contract = new ethers.Contract(TeamContractAddress, LionTeam.abi, signer);
                                const depositValuetInWei = ethers.utils.parseEther(depositamounteth);
                          
                                try {

                                  
                                  const transaction = await contract.withdrawlMarketing(depositValuetInWei,depositrecievereth,depositaddressReasonEth,depositipfseth,deposittelegrameth);
                                  await transaction.wait( Swal.fire(swalOptions));
                                  Toast.fire({icon: 'success',title: 'Eth Sent Success'})
                                  Swal.fire({
                                    icon: 'success',
                                    title: 'Transaction Successful',
                                    text: 'Sent Successfully!',
                                  })
                                 
                                }
                                catch (error) {
                                 
                                  console.log(error.message);
                                  Swal.fire({
                                    icon: 'error',
                                    title: 'Transaction Failed',
                                    text: error.reason,
                                  });
                                  
                                }
                              }
                            }
                      
                            async function handleClaimNFT() {
                              if(typeof window.ethereum !== 'undefined') {
                                const provider = new ethers.providers.Web3Provider(window.ethereum);
                                const signer = provider.getSigner();
                                const contract = new ethers.Contract(TeamContractAddress, LionTeam.abi, signer);
                          
                                try {
                                  
                                  const transaction = await contract.withdrawlNFT();
                                  await transaction.wait( Swal.fire(swalOptions));
                                  Toast.fire({icon: 'success',title: 'Claim Success'})
                                  Swal.fire({
                                    icon: 'success',
                                    title: 'Transaction Successful',
                                    text: 'Sent Points Successfully!',
                                  })
                                 
                                }
                                catch (error) {
                                 
                                  console.log(error.message);
                                  Swal.fire({
                                    icon: 'error',
                                    title: 'Transaction Failed',
                                    text: error.reason,
                                  });
                                  
                                }
                              }
                            }
                      
                            async function handleClaimExpense() {
                              if(typeof window.ethereum !== 'undefined') {
                                const provider = new ethers.providers.Web3Provider(window.ethereum);
                                const signer = provider.getSigner();
                                const contract = new ethers.Contract(TeamContractAddress, LionTeam.abi, signer);
                          
                                try {
                                  
                                  const transaction = await contract.withdrawlexpenses();
                                  await transaction.wait( Swal.fire(swalOptions));
                                  Toast.fire({icon: 'success',title: 'Claim Success'})
                                  Swal.fire({
                                    icon: 'success',
                                    title: 'Transaction Successful',
                                    text: 'Sent Points Successfully!',
                                  })
                                 
                                }
                                catch (error) {
                                 
                                  console.log(error.message);
                                  Swal.fire({
                                    icon: 'error',
                                    title: 'Transaction Failed',
                                    text: error.reason,
                                  });
                                  
                                }
                              }
                            }







return (

  <Box mb="100px">
            <Box>

                <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)'}} gap="1px">

                    <Card className='NFTHead'    textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxt'>
                          Information Hub
                        
                        </CardHeader>
                        <CardBody>
                        
               
         

                     
                     </CardBody>
                     
                     <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)'}} gap="10px">
                    

                     <Card className='NFTHead'   textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                     <Text color="whiteAlpha.800" >Marketing Funds </Text>
                    <Text className="responseTop" color="whiteAlpha.800">{marketing}</Text>
                    </Card>
                    <Card className='NFTHead'   textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                     <Text color="whiteAlpha.800" >NFT Funds</Text>
                    <Text className="responseTop" color="whiteAlpha.800">{NFT}</Text>
                    </Card>
                    <Card className='NFTHead'   textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                     <Text color="whiteAlpha.800" >INNOVA Balance</Text>
                    <Text className="responseTop" color="whiteAlpha.800">{tokenBalance}</Text>
                    </Card>
                    


                    <Card className='NFTHead'   textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                     <Text color="whiteAlpha.800" >Admin 1 Balance</Text>
                    <Text className="responseTop" color="whiteAlpha.800">{admin1Balance}</Text>
                    </Card>
                    <Card className='NFTHead'   textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                     <Text color="whiteAlpha.800" >Equity Balance</Text>
                    <Text className="responseTop" color="whiteAlpha.800">{equityBalance}</Text>
                    </Card>
                    <Card className='NFTHead'   textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                     <Text color="whiteAlpha.800" >Admin 2 Balance</Text>
                    <Text className="responseTop" color="whiteAlpha.800">{admin2Balance}</Text>
                    </Card>
                   
                 
                    </Grid>  
                   




<br></br>




<Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)'}} gap="1px">



</Grid>
                      
                    
                    <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)'}} gap="1px">
                    <Button className='btnsubmit1' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => handleClaimNFT()}>
                    <font><b>Claim NFT</b></font>
                    </Button>
                    <Button className='btnsubmit1' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => handleClaimAdmin1()}>
                    <font><b>Claim Admin1</b></font>
                    </Button>
                    <Button className='btnsubmit1' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => handleClaimAdmin2()}>
                    <font><b>Claim Admin2</b></font>
                    </Button>
                    <Button className='btnsubmit1' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => handleClaimExpense()}>
                    <font><b>Claim Equity</b></font>
                    </Button>
                    </Grid>  




                    <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}} gap="1px">
 
                   
                    
              

                    <Card className='NFTHead'    textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxt'>
                          Marketing ETH Send
                        
                        </CardHeader>
                       
                        <CardBody>
                        <Text color="whiteAlpha.800" >Amount</Text>
                        <input type="text" className="form-control" onChange={handleDepositAmountChangeEth} value={depositamounteth} placeholder="Amount"></input> 
                        <br>
                        </br>
                        <br>
                        </br>
                       
                        <Text color="whiteAlpha.800" >Reciever</Text>
                        <input type="text" className="form-control" onChange={handleDepositAddressChangeEth} value={depositrecievereth} placeholder="Address"></input> 
                        <br>
                        </br>
                        <br>
                        </br>
                    
                      
                        <Text color="whiteAlpha.800" >Reason For Withdrawl</Text>
                        <input type="text" className="form-control" onChange={handleDepositChangeReasonEth} value={depositaddressReasonEth} placeholder="Reason"></input> 
                       
                        <br>
                        </br>
                        <br>
                        </br>
                        <Text color="whiteAlpha.800" >IPFS Link</Text>
                        <input type="text" className="form-control" onChange={handleDepositChangeIPFSEth} value={depositipfseth} placeholder="IPFS Link"></input> 
                        <br>
                        </br>
                        <br>
                        </br>
                      
                        <Text color="whiteAlpha.800" >Telegram @</Text>
                        <input type="text" className="form-control" onChange={handleDepositChangeTelegramEth} value={deposittelegrameth} placeholder="Telegram"></input> 
                        <br>
                        </br>
                        <br>
                        </br>
                       
                       
                       
                        <Button className='btnsubmit1' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => handleSendEth()}>
                  <font><b>Send Tokens</b></font>
                  </Button>
                  </CardBody>
                  </Card>



                  <Card className='NFTHead'    textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxt'>
                        Marketing Token Send
                        
                        </CardHeader>
                        <CardBody>



  

  
                       
                    <Text color="whiteAlpha.800" >Amount</Text>
                        <input type="text" className="form-control" onChange={handleDepositAmountChangeTokens} value={depositamounttokens} placeholder="Amount"></input> 
                        <br>
                        </br>
                        <br>
                        </br>
                       
                        <Text color="whiteAlpha.800" >Reciever</Text>
                        <input type="text" className="form-control" onChange={handleDepositAddressChangeTokens} value={depositrecievertokens} placeholder="Address"></input> 
                        <br>
                        </br>
                        <br>
                        </br>
                    
                      
                        <Text color="whiteAlpha.800" >Reason For Withdrawl</Text>
                        <input type="text" className="form-control" onChange={handleDepositChangeReasonTokens} value={depositaddressReasonToken} placeholder="Reason"></input> 
                       
                        <br>
                        </br>
                        <br>
                        </br>
                        <Text color="whiteAlpha.800" >IPFS Link</Text>
                        <input type="text" className="form-control" onChange={handleDepositChangeIPFSTokens} value={depositipfstokens} placeholder="IPFS Link"></input> 
                        <br>
                        </br>
                        <br>
                        </br>
                      
                        <Text color="whiteAlpha.800" >Telegram @</Text>
                        <input type="text" className="form-control" onChange={handleDepositChangeTelegramTokens} value={deposittelegramtokens} placeholder="Telegram"></input> 
                        <br>
                        </br>
                        <br>
                        </br>
                       
                       
                       
                        <Button className='btnsubmit1' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => handleSendTokens()}>
                  <font><b>Send Tokens</b></font>
                  </Button>
                  </CardBody>
                  </Card>








                    </Grid>  
                    </Card>
                    </Grid>  

                    
                 
                 
             </Box>

        </Box>
    );
};
export default NFTHead;