import { Box, Flex, Grid, Image, Card, CardBody, CardFooter,Stack, Text, Button, CardHeader, Center } from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Link } from 'react-router-dom';



import { ethers } from 'ethers';
import { useAccount } from 'wagmi';

import LionStaking from '../artifacts/contracts/LionStaking.sol/LionStaking.json';
import LionNft from '../artifacts/contracts/LionNFT.sol/LionNFT.json';
import Swal from 'sweetalert2';
import '../Styles/Home.css';

import NFTHeader from './NFTHead'






const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,

  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})


const stakingContractAddress = '0x4743305b5D4814239971D55FF8e4f3dB64E5557A'; 

const nftContractAddress = '0x4743305b5D4814239971D55FF8e4f3dB64E5557A';

const Logo = "https://nftstorage.link/ipfs/bafybeibvxjkt4feljnjsu7jumosfxxmoyffscdcn3d5btzpvq5r6ypzjfm/"


const Campaigns = () => {
 
  const [campaignData, setCampaignData] = useState([]);
  const [depositValueMin, setDepositValueMin] = useState();
  const [depositValueCampType, setDepositCampType] = useState();
  const [depositValueEth, setDepositValueEth] = useState();
  const [depositValueEthRwd, setDepositValueEthRwd] = useState();


  
  const [addr, setaddress] = useState();
  const [name, setname] = useState();
  const [min, setmin] = useState();
  const [status, setstatus] = useState();

  const [percent, setpercent] = useState();
  const [Tknpercent, setTknpercent] = useState();
  const [expires, setexpires] = useState();


  const { address, isDisconnected } = useAccount();
  const [ownerTokensId, setOwnerTokensId] = useState([]);
  const [nftimguri, settokenUri] = useState([])
  const [depositValueMinActive, setDepositValueMinActive] = useState();
  const [depositValueIDActive, setDepositValueIdActive] = useState();


  const [depositValueID, setDepositValueId] = useState();
  const [depositValueAddAmount, setDepositValueAddAmount] = useState();
  const [depositValueAddress, setDepositValueAddress] = useState();
  const [depositValueContract, setDepositValueContract] = useState();
 

 
 

   
       
  useEffect(() => {
    fetchNftData();
 
    
    updateData();
}, [address])
        

    const updateData = () => {
      if(isDisconnected) {
        setOwnerTokensId([]);
        settokenUri();

       
        
       
        
      }
    }




 
    async function fetchNftData() {
      if (typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(nftContractAddress, LionNft.abi, provider);
        try {
          if (address) {
            const tokensId = await contract.walletOfOwner(address);
            const ownerTokensId = tokensId.map((tokenId) => tokenId.toString());
    
            const updatedCampaignData = await Promise.all(
              ownerTokensId.map(async (token) => {
                const updatedTokenData = await fetchCouponInfo(provider, token);
                return {
                  token,
                
                  ...updatedTokenData,
                };
              })
            );
            setCampaignData(updatedCampaignData);
              console.log("rebootFetschNFtData")
           

    
     
    
          }
        } catch (err) {
          console.log(err.message);
        }
      }
    }
    
    
  
    
    



   

      

   
    
    


   

    




    async function fetchCouponInfo(provider, token) {
      const contract = new ethers.Contract(stakingContractAddress, LionNft.abi, provider);
      try {
        const newToken = ethers.BigNumber.from(token).toString();
        const coupon = await contract.dev(newToken);
        return {
          id: coupon[0].toString(),
          token: coupon[2].toString(),
          wethMade: coupon[4].toString(),
         
        };
        
       
      } catch (err) {
        console.log(err.message);
        
      }
    }
    
    


return (


  <Box border="2px" borderRadius="30px" px="20px" pb="25px">
     <NFTHeader />
    <Flex justifyContent="space-between" alignItems="center" wrap="wrap" gap="25px" my="25px">
    <Text className='cardtxt' fontSize="30px" fontWeight="bold">Your Disappearing Devs </Text>


    
    
 

    
     
      {!isDisconnected}
    </Flex>
    <br></br>
    <br></br>

    <Grid templateColumns={{base: 'repeat(1, fr)', md: 'repeat(3, 1fr)'}} gap="20px">
 
      {campaignData.map((campaign, index) => (
        <Card direction={{ base: 'column', sm: 'row' }} backgroundColor="#0a0a0a" key={index}>
          <CardBody className="cardbody" overflow="hidden" borderTopRadius="80px">
                <div className='nft-container'>
                  <br></br>
                  <Flex justify="center">
              <Image src={Logo + campaign.id + ".png"} alt="nftImage" borderRadius="30px" w={{ base: 'auto', lg: '300px' }} height="200px"  objectFit="contain" />
       
                  </Flex>
                  <br></br>
                 
                  <Flex justify="center">
                     </Flex>
                  <Flex className='yelloe' justify="center">Dev ID</Flex>
                  <Flex justify="center">{campaign.id}</Flex>
                  <br></br>
                  <Flex className='yelloe' justify="center">Points Made</Flex>
                  <Flex justify="center">{campaign.token}</Flex>
                  <br></br>
                  <Flex className='yelloe' justify="center">WETH Made</Flex>
                  <Flex justify="center">{campaign.wethMade}</Flex>
                  <br></br>
                



           
                </div>
             
          </CardBody>
        </Card>
      ))}
    </Grid>
    
   
  </Box>
);
};
export default Campaigns;