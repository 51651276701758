import { Box, Flex, Grid, Image, Card, CardBody, CardFooter,Stack, Text, Button, CardHeader, Center } from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';

import 'reactjs-popup/dist/index.css';
import { Link } from 'react-router-dom';



import { ethers } from 'ethers';
import { useAccount } from 'wagmi';

import LionToken from '../artifacts/contracts/LionToken.sol/LionToken.json';
import LionNft from '../artifacts/contracts/LionNFT.sol/LionNFT.json';

import Swal from 'sweetalert2';
import '../Styles/Home.css';
import Logo from '../Images/logo.png'




const Toast = Swal.mixin({
  toast: true,
  position: 'center',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,

  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const swalOptions = {
  icon: 'info',
  title: 'Transaction Pending',
  text: 'Please wait while the transaction is being processed...',
  allowOutsideClick: true,
  showCancelButton: false,
  showConfirmButton: true,
  timer: 5000, // Set timer for 5 seconds (5000 milliseconds)
  onBeforeOpen: () => {
    Swal.showLoading();
  }
};


const TokenContractAddress = '0xca9d8ef4ba15ae66347b3d22afe2970b89980f88'; 

const nftContractAddress = '0x4743305b5D4814239971D55FF8e4f3dB64E5557A';


const NFTHead = () => {
 

  const [totalPoints, settotalPoints] = useState();
  const [pps, setpps] = useState();
  const [level, setLevel] = useState();
  const [strike, setstrike] = useState();
  const [given, setGiven] = useState();
  const [claim, setClaim] = useState();
  const [weth, setclaimableWETH] = useState();
  const [ppt, setppt] = useState();
  const [claimablePoints, setclaimablePoints] = useState();
  const [claimableInnova, setclaimableInnova] = useState();
  const [tokenBalance, setBalance] = useState();
  const [nftBalance, setNFTBalance] = useState();
 

 


  const { address, isDisconnected } = useAccount();




  const [depositaddress, setDepositValueAddress] = useState();
  const [BurnAmt, setDepositValueBurn] = useState();

 

 
 

   
       
  useEffect(() => {
    viewUser()
   
    viewUserBalance();
    viewTokenBalance()
    viewNFTBalance() 
    viewClaimableInnova()
    viewClaimablePoints()
    viewPpt()
}, [address])


//------------SET MINT FUNCTIONS HERE--------------------


    const updateData = () => {
      if(isDisconnected) {
        viewUser()
        viewUserBalance();
        updateData();
        viewTokenBalance()
        viewNFTBalance() 
        viewClaimablePoints()
        viewClaimableInnova()
        viewPpt()
    

        
      }
    }

    const handleDepositChangeContract = (e) => {
      setDepositValueAddress(e.target.value);
    };

    const handleDepositChangeBurn = (e) => {
      setDepositValueBurn(e.target.value);
    };


    async function viewUser() {
      if(typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(nftContractAddress, LionNft.abi, provider);
        try {
          if(address) {
            const rum = await contract.user(address);
            let amounts = await(rum);
            let totalPoints  = amounts[1].toString();
            let pps  = amounts[4].toString();
            let level  = amounts[5].toString();
            let strike  = amounts[6].toString();
            let given  = amounts[7].toString();
            let claim  = amounts[8].toString();

            settotalPoints(totalPoints);
            setpps(pps);
            setLevel(level);
            setstrike(strike);
            setGiven(given);
            setClaim(claim);  
            console.log("virewUserRefresh")
          
         
               

          }}
          catch (err) {
            console.log(err.message);
          }
        }
    }


    async function handlePointsSend() {
      if(typeof window.ethereum !== 'undefined') {
        console.log(depositaddress)
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(nftContractAddress, LionNft.abi, signer);
  
        try {
          
          const transaction = await contract.distributePoints(depositaddress);
       
        
          await transaction.wait( Swal.fire(swalOptions));
          Toast.fire({icon: 'success',title: 'Points Sent'})
          Swal.fire({
            icon: 'success',
            title: 'Transaction Successful',
            text: 'Sent Points Successfully!',
          })
          viewUser();
          viewUserBalance();
          viewTokenBalance();
          viewNFTBalance();
         
         
        }
        catch (error) {
         
          console.log(error.message);
          Swal.fire({
            icon: 'error',
            title: 'Transaction Failed',
            text: error.reason,
          });
          
        }
      }
    }






    async function handlePointsBurn() {
      if(typeof window.ethereum !== 'undefined') {
        console.log(BurnAmt)
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(nftContractAddress, LionNft.abi, signer);
  
        try {
          
          const transaction = await contract.burnPoints(BurnAmt);
       
        
          await transaction.wait( Swal.fire(swalOptions));
          Toast.fire({icon: 'success',title: 'Points Burnt'})
          Swal.fire({
            icon: 'success',
            title: 'Transaction Successful',
            text: 'Burnt Points Successfully!',
          })
          viewUser();
          viewUserBalance();
          viewTokenBalance();
          viewNFTBalance();
         
         
        }
        catch (error) {
         
          console.log(error.message);
          Swal.fire({
            icon: 'error',
            title: 'Transaction Failed',
            text: error.reason,
          });
          
        }
      }
    }


    async function handlePointsClaim() {
      if(typeof window.ethereum !== 'undefined') {
        console.log(depositaddress)
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(nftContractAddress, LionNft.abi, signer);
  
        try {
          
          const transaction = await contract.claimPoints();
       
        
          await transaction.wait( Swal.fire(swalOptions));
          Toast.fire({icon: 'success',title: 'Points Claimed'})
          Swal.fire({
            icon: 'success',
            title: 'Transaction Successful',
            text: 'Points Claimed Successfully!',
          })
          viewUser();
          viewUserBalance();
          viewTokenBalance();
          viewNFTBalance();
         
          
         
        }
        catch (error) {
         
          console.log(error.message);
          Swal.fire({
            icon: 'error',
            title: 'Transaction Failed',
            text: error.reason,
          });
          
        }
      }
    }


    async function viewUserBalance() {
      if(typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(nftContractAddress, LionNft.abi, provider);
        try {
          if(address) {
            const rum = await contract.viewPendingRewards(address);
            const newamt = (rum/(10**18)).toFixed(4);

            let total = newamt.toString();
            
            setclaimableWETH(total)

            console.log("fetchuserbal")
           

               
          }}
          catch (err) {
            console.log(err.message);
          }
        }}


        async function viewPpt() {
          if(typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(nftContractAddress, LionNft.abi, provider);
            try {
              if(address) {
                const rum = await contract.pointsPerToken();
                
               
                let total = rum.toString();
                
                setppt(total)
    
                console.log("fetchuserbal")
               
    
                   
              }}
              catch (err) {
                console.log(err.message);
              }
            }}


        async function viewClaimablePoints() {
          if(typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner(address); 
            const contract = new ethers.Contract(nftContractAddress, LionNft.abi, signer);
            try {
              if(address) {
                const rum = await contract.getClaimablePoints();
                
                let total = rum.toString();
                
                setclaimablePoints(total)
    
                console.log(total) 

                setTimeout(viewClaimablePoints, 5000 );

              
              }}
              catch (err) {
                console.log(err.message);
              }
            }}



            async function viewClaimableInnova() {
              if(typeof window.ethereum !== 'undefined') {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner(address); 
                const contract = new ethers.Contract(nftContractAddress, LionNft.abi, signer);
                try {
                  if(address) {
                    const rum = await contract.ViewClaimableTokens();
                    
                    let total = rum.toString();
                    
                    setclaimableInnova(total)
        
                    console.log(total) 
    
                    setTimeout(viewClaimableInnova, 5000 );
    
                  
                  }}
                  catch (err) {
                    console.log(err.message);
                  }
                }}
    





        async function viewTokenBalance() {
          if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(TokenContractAddress, LionToken.abi, provider);
            try {
              if (address) {
                const rum = await contract.balanceOf(address);
                const balanceInEther = ethers.utils.formatEther(rum);
                const balanceWithTwoDecimalPlaces = parseFloat(balanceInEther).toFixed(2);
                setBalance(balanceWithTwoDecimalPlaces);
                console.log("fetchusertokenbal")
              
                
              }
            } catch (err) {
              console.log(err.message);
            }
          }
        }


        async function viewNFTBalance() {
          if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(nftContractAddress, LionNft.abi, provider);
            try {
              if (address) {
                const rum = await contract.balanceOf(address);
                const amount = rum.toString()
               
             
                setNFTBalance(amount);
                console.log("fetchuserNFTbal")
              
              }
            } catch (err) {
              console.log(err.message);
            }
          }
        }


    async function handleWethClaim() {
      if(typeof window.ethereum !== 'undefined') {
        console.log(depositaddress)
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(nftContractAddress, LionNft.abi, signer);
  
        try {
          
          const transaction = await contract.claimRewards();
       
        
          await transaction.wait( Swal.fire(swalOptions));
          Toast.fire({icon: 'success',title: 'WETH Claimed'})
          Swal.fire({
            icon: 'success',
            title: 'Transaction Successful',
            text: 'WETH Claim Successful!',
          })
          viewUser();
          viewUserBalance();
          viewTokenBalance();
          viewNFTBalance();
         
        }
        catch (error) {
         
          console.log(error.message);
          Swal.fire({
            icon: 'error',
            title: 'Transaction Failed',
            text: error.reason,
          });
          
        }
      }
    }


    async function handleCashIn() {
      if(typeof window.ethereum !== 'undefined') {
        console.log(depositaddress)
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(nftContractAddress, LionNft.abi, signer);
  
        try {
          
          const transaction = await contract.cashInPoints();
       
        
          await transaction.wait (Swal.fire(swalOptions));
          Swal.fire({
            icon: 'success',
            title: 'Transaction Successful',
            text: 'Cash In Successful!',
          })
          viewUser();
          viewUserBalance();
          viewTokenBalance();
          viewNFTBalance();
          
        }
        catch (error) {
         
          console.log(error.message);
          Swal.fire({
            icon: 'error',
            title: 'Transaction Failed',
            text: error.reason,
          });
          
        }
      }
    }




return (

  <Box mb="100px">
            <Box>

                <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)'}} gap="1px">

                  

                    <Card className='NFTHead'    textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxt'>
                          Points Hub
                        
                        </CardHeader>

              



                       <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}} gap="10px">
                        <Card className='NFTHead'   textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                   
                        <CardHeader className='cardtxt'>
                          Send Points
                        
                        </CardHeader>
                    <Text color="whiteAlpha.800" >Input Address To Recieve 10% Of Your Points.</Text>
                    <br>
                        </br>
                        <input type="text" className="form-control" onChange={handleDepositChangeContract} value={depositaddress} placeholder="Address"></input> 
                        <br>
                        </br>
                        
                      
                       
                       
                        <Button className='btnsubmit1' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => handlePointsSend()}>
                        <font><b>Send Points</b></font>
                        </Button>

                     
                     </Card>
         








                     <Card className='NFTHead'   textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                     <CardHeader className='cardtxt'>
                          Burn Points
                        
                        </CardHeader>
                        
                      <Text color="whiteAlpha.800" >Input Amount Of Points To Burn</Text>
                      <br>
                        </br>
                       <input type="text" className="form-control" onChange={handleDepositChangeBurn} value={BurnAmt} placeholder="1000"></input> 
                       <br>
                       </br>
                      
                      
                      
                       <Button className='btnsubmit1' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => handlePointsBurn()}>
                       <font><b>Burn Points</b></font>
                       </Button>

                    
                    </Card>
                     </Grid>
                     











                     <br>
                        </br>
                        <br>
                        </br>

                        <CardHeader className='cardtxt'>
                          Information Hub
                        
                        </CardHeader>
                     <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)'}} gap="10px">
                    
                   

                     <Card className='NFTHead'   textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                     <Text color="whiteAlpha.800" >User Rate </Text>
                    <Text className="responseTop" color="whiteAlpha.800">{pps}</Text>
                    </Card>
                    <Card className='NFTHead'   textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                     <Text color="whiteAlpha.800" >Can Trade-In</Text>
                    <Text className="responseTop" color="whiteAlpha.800">{claim}</Text>
                    </Card>
                    <Card className='NFTHead'   textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                     <Text color="whiteAlpha.800" >User Total Points</Text>
                    <Text className="responseTop" color="whiteAlpha.800">{totalPoints}</Text>
                    </Card>
                    


                    <Card className='NFTHead'   textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                     <Text color="whiteAlpha.800" >User Given Points</Text>
                    <Text className="responseTop" color="whiteAlpha.800">{given}</Text>
                    </Card>
                    <Card className='NFTHead'   textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                     <Text color="whiteAlpha.800" >User Level</Text>
                    <Text className="responseTop" color="whiteAlpha.800">{level}</Text>
                    </Card>
                    <Card className='NFTHead'   textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                     <Text color="whiteAlpha.800" >User Strike</Text>
                    <Text className="responseTop" color="whiteAlpha.800">{strike}</Text>
                    </Card>



                    <Card className='NFTHead'   textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                     <Text color="whiteAlpha.800" >NFT Balance</Text>
                    <Text className="responseTop" color="whiteAlpha.800">{nftBalance}</Text>
                    </Card>
                    <Card className='NFTHead'   textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                     <Text color="whiteAlpha.800" >Token Balance</Text>
                    <Text className="responseTop" color="whiteAlpha.800">{tokenBalance}</Text>
                    </Card>
                    <Card className='NFTHead'   textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                     <Text color="whiteAlpha.800" >Claimable WETH</Text>
                    <Text className="responseTop" color="whiteAlpha.800">{weth}</Text>
                    </Card>


                    <Card className='NFTHead'   textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                     <Text color="whiteAlpha.800" >Claimable Points</Text>
                    <Text className="responseTop" color="whiteAlpha.800">{claimablePoints}</Text>
                    </Card>
                    <Card className='NFTHead'   textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                     <Text color="whiteAlpha.800" >Points Per Token</Text>
                    <Text className="responseTop" color="whiteAlpha.800">{ppt}</Text>
                    </Card>
                    <Card className='NFTHead'   textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="30px" fontWeight="bold">
                     <Text color="whiteAlpha.800" >Claimable INNOVA</Text>
                    <Text className="responseTop" color="whiteAlpha.800">{claimableInnova}</Text>
                    </Card>
                   
                 
                    </Grid>  
                   

                    


<br></br>




                    <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)'}} gap="1px">

                    <Card className='NFTHead'textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                      
                    
                    <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)'}} gap="1px">


                    <Card className='NFTHead'  textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                    <Button className='btnsubmit1' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => handlePointsClaim()}>
                    <font><b>Claim Points</b></font>
                    </Button>
                    </Card>
                    <Button className='btnsubmit1' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => handleCashIn()}>
                    <font><b>Cash In Points</b></font>
                    </Button>
                    <Button className='btnsubmit1' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => handleWethClaim()}>
                    <font><b>Claim WETH</b></font>
                    </Button>
                    <Card className='NFTHead'  textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                      <br></br>
                    </Card>
                    </Grid>  

                    </Card>
                    </Grid>
                  
                   
           
                     
                  </Card>
                  </Grid>
             </Box>

        </Box>
    );
};
export default NFTHead;