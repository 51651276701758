import React, {useState, useEffect} from 'react';
import Logo from '../Images/logo1.png';
import { Box, Heading, Button, Card, CardBody, Image, CardHeader, Flex, Grid, Text } from '@chakra-ui/react';
import UnstakedNFT from 'Components/UnstakedNFT';
import '../Styles/Home.css'
import DAO from '../Images/DAO.png'
import NFT from '../Images/NFT.png'
import Lock from '../Images/NO ENTRY.png'

import { ethers } from 'ethers';
import { useAccount } from 'wagmi';


import { Link } from 'react-router-dom';

const Staking = () => {
  const { address, isConnecting, isDisconnected } = useAccount();

 

  return (
    <Box mb="100px">
      <Box>
        <center>
         
          
          <br />
          <Heading className='headertxt' bgClip='text' />
          <br />
          <Heading className='headertxt' bgClip='text'>
            Welcome To The INNOVA Ecosystem
            <Text color="whiteAlpha.800" />
          </Heading>
          <br />
          <Text color="whiteAlpha.800" fontSize='13px'>
          Here you will be able to interact with the entire suite of tools and applications INNOVA has to offer.
          </Text>
        </center>
        <br />
        <br />
        <br />
        <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)'}} gap="60px">
          <Card textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px" fontSize="25px" fontWeight="bold">
            <Link to="/Innovasight">
              <CardHeader className='cardtxt'>
                <Image
                  className='bnbpool'
                  src={DAO}
                  alt=''
                  transform="scale(1)"
                  width='150px'
                  height='150px'
                  transition= "0.3s ease-in-out"
                  _hover={{transform: "scale(1.15)"}}
                />
                  <br></br>
                Beta V1.0 INNOVA ONLY
                <br></br>
                <br></br>
                <Text color="whiteAlpha.800" fontSize='13px'>
                  Keep Developers, Marketers & Teams accountable for their actions using INNOVASIGHT.
           </Text>

              <br></br>
              <a href="/">
  <Button
    className='btnsubmit1'
    border='1px'
    borderRadius={30}
    variant='transparent'
    marginInline={2}
    fontSize="15px"
    colorScheme='blue'
    color='#E9D8FD'
   
  >
    <font><b>Enter Dashboard</b></font>
  </Button>
</a>
              </CardHeader>
            </Link>
          </Card>
          <Card textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px" fontSize="25px" fontWeight="bold">
           
              <CardHeader className='cardtxt'>
                <Image
                  className='bnbpool'
                  src={NFT}
                  alt=''
                  transform="scale(1)"
                  width='150px'
                  height='150px'
                  transition= "0.3s ease-in-out"
                  _hover={{transform: "scale(1.15)"}}
                />
                <br />
                Disappearing Devs
                <br></br>

<br></br>
                <Text color="whiteAlpha.800" fontSize='13px'>
                Our NFTs are more than just images; they're a cornerstone of our ecosystem, driving growth and providing passive ETH reflections.
                </Text>
              </CardHeader>
           
            <a href="/Mint">
  <Button
    className='btnsubmit1'
    border='1px'
    borderRadius={30}
    variant='transparent'
    marginInline={2}
    fontSize="15px"
    colorScheme='blue'
    color='#E9D8FD'
   
  >
    <font><b>Mint</b></font>
  </Button>
</a>
            <a href="/NFTDash">
  <Button
    className='btnsubmit1'
    border='1px'
    borderRadius={30}
    variant='transparent'
    marginInline={2}
    fontSize="15px"
    colorScheme='blue'
    color='#E9D8FD'
   
  >
    <font><b>Dashboard</b></font>
  </Button>
</a>
          </Card>
          <Card textAlign="center" border="2px" borderColor='blue.
          50' borderRadius="30px" fontSize="25px" fontWeight="bold">
          <Link to="/">
          <CardHeader className='cardtxt'>
          <Image
          className='bnbpool'
          src={Lock}
          alt=''
          transform="scale(1)"
          width='150px'
          height='150px'
          transition= "0.3s ease-in-out"
          _hover={{transform: "scale(1.15)"}}
          />
          <br />
          ...In Development...
          <br></br>

<br></br>
          <Text color="whiteAlpha.800" fontSize='13px'>
          Our primary utility is essential, but we're unable to disclose full details due to the presence of numerous thieving eyes.
                </Text>
          </CardHeader>
          </Link>
          </Card>
          </Grid>
          <Box textAlign="center" mt="25px" />
          </Box>
          <Box>
          <Text textAlign="center" fontSize="30px" color="white" fontWeight="bold" my="35px"></Text>
          <Grid templateColumns={{base: 'repeat(1, 1fr)', lg: 'repeat(1, 1fr)'}} gap="60px">
          <UnstakedNFT />
          </Grid>
          <br>
          </br>
          <br></br>
          </Box>
          </Box>
          
          );
          };
          
          export default Staking;